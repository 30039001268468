import React from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";

const containerStyle = {
  width: "90%",
  height: "50vh",
};

const center = {
  lat: 44.8217933,
  lng: 20.4123438,
};

export default function Map() {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyD-NAXXIEM9T_2EfL7BlF7ffQdp1oIh9gM",
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  return (
    <div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={15}
        center={center}
        mapContainerClassName="map-container"
      >
        <MarkerF position={center} visible={true} />
      </GoogleMap>
    </div>
  );
}
