type ModalProps = {
  children: React.JSX.Element;
  show: boolean;
  onClose: () => void;
};

export default function Modal({ children, show, onClose }: ModalProps) {
  return show ? (
    <>
      <div className="modal-backdrop" onClick={onClose} />
      <div className="modal-wrapper" onClick={onClose}>
        <div className="modal-content">
          <div className="modal-body">{children}</div>
        </div>
      </div>
    </>
  ) : null;
}
