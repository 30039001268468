import { useEffect, useMemo } from "react";
import useLanguageProvider from "../providers/LanguageProvider/useLanguageProvider";
import useNavbarProvider from "../providers/NavbarProvider/useNavbarProvider";

export default function CooperationPage() {
  const { language } = useLanguageProvider();
  const { setNavbarItem } = useNavbarProvider();

  useEffect(() => {
    setNavbarItem("cooperation");
  }, [setNavbarItem]);

  const title = useMemo(() => {
    if (language === "serbian") {
      return "Naši saradnici";
    }
    return "Our cooperators";
  }, [language]);

  return (
    <>
      <section className="trainer_section">
        <div className="container">
          <div className="heading_container">
            <h2>{title}</h2>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 mx-auto">
              <div className="box">
                <div className="name">
                  <h5>REKREACIJA D.O.O</h5>
                </div>
                <div className="img-box">
                  <img
                    src={require("../images/saradnja/rekreacija.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mx-auto">
              <div className="box">
                <div className="name">
                  <h5>PROTEINI.SI</h5>
                </div>
                <div className="img-box">
                  <img
                    src={require("../images/saradnja/proteini.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mx-auto">
              <div className="box">
                <div className="name">
                  <h5>ATP SPORT</h5>
                </div>
                <div className="img-box">
                  <img src={require("../images/saradnja/atp.jpeg")} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6 mx-auto">
              <div className="box">
                <div className="name">
                  <h5>BOMBBAR</h5>
                </div>
                <div className="img-box">
                  <img src={require("../images/saradnja/bombbar.png")} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mx-auto">
              <div className="box">
                <div className="name">
                  <h5>FIT IN</h5>
                </div>
                <div className="img-box">
                  <img src={require("../images/saradnja/fitin.png")} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mx-auto">
              <div className="box">
                <div className="name">
                  <h5>FITPASS</h5>
                </div>
                <div className="img-box">
                  <img src={require("../images/saradnja/fitpass.jpg")} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
