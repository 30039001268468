import React, { useState } from "react";
import { Provider } from "./context";

export default function NavbarProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [navbarItem, setNavbarItem] = useState("home");

  return <Provider value={{ navbarItem, setNavbarItem }}>{children}</Provider>;
}
