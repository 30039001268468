import React, { Dispatch, SetStateAction } from "react";

const defaultValue = {
  language: "serbian",
  setLanguage: ((value: string) => undefined) as Dispatch<
    SetStateAction<string>
  >,
};

const LanguageContext = React.createContext(defaultValue);

const { Provider, Consumer } = LanguageContext;

export { LanguageContext, Provider, Consumer };
