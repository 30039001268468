import React from "react";

type SliderProps = {
  images: string[];
  active: number;
  setActive: (v: number) => void;
};

export const Slider = ({ images, active, setActive }: SliderProps) => {
  const onNext = (event: React.MouseEvent) => {
    if (active < images.length - 1) {
      setActive(active + 1);
      event.stopPropagation();
    }
  };

  const onPrev = (event: React.MouseEvent) => {
    if (active > 0) {
      setActive(active - 1);
      event.stopPropagation();
    }
  };

  return (
    <div className="slider">
      <div className="slides">
        {images.map((url, i) => (
          <Slide image_url={url} active={i === active} />
        ))}
      </div>
      <div className="navigation">
        <div className="navigation-next-prev">
          <div className="next-prev prev" onClick={onPrev}>
            <i className="fa fa-arrow-left" />
          </div>
          <div className="next-prev next" onClick={onNext}>
            <i className="fa fa-arrow-right" />
          </div>
        </div>
      </div>
    </div>
  );
};

type SlideProps = {
  image_url: string;
  active: boolean;
};

const Slide = ({ image_url, active }: SlideProps) => {
  return (
    <div className={`slide ${active ? "active" : ""}`}>
      <img src={image_url} alt="" />
    </div>
  );
};
