import { useEffect, useMemo } from "react";
import useLanguageProvider from "../providers/LanguageProvider/useLanguageProvider";
import useNavbarProvider from "../providers/NavbarProvider/useNavbarProvider";
import Map from "./Map";

export default function ContactPage() {
  const { language } = useLanguageProvider();
  const { setNavbarItem } = useNavbarProvider();

  useEffect(() => {
    setNavbarItem("contact");
  }, [setNavbarItem]);

  const content = useMemo(() => {
    if (language === "serbian") {
      return {
        CONTACT_HEADER: "Kontakt informacije:",
        HOURS_HEADER: "Radno vreme:",
        WEEK: "Ponedeljak-Petak",
        SATURDAY: "Subota",
        SUNDAY: "Nedelja",
      };
    }
    return {
      CONTACT_HEADER: "Contact information:",
      HOURS_HEADER: "Working hours:",
      WEEK: "Monday-Friday",
      SATURDAY: "Saturday",
      SUNDAY: "Sunday",
    };
  }, [language]);

  return (
    <>
      <section className="contact_section ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="img-box">
                <Map />
              </div>
              <div className="img-box">
                <img src={require("../images/logo-gymbox.png")} alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="form_container">
                <div className="direct-contact-container">
                  <div>
                    <h3>{content.CONTACT_HEADER}</h3>
                  </div>

                  <ul className="contact-list">
                    <li className="list-item">
                      <i className="fa fa-map-marker fa-2x">
                        <span className="contact-text place">
                          <a href="https://www.google.com/maps/place/GymBox/@44.8217933,20.4123438,17z/data=!3m1!4b1!4m6!3m5!1s0x475a65003ca0c5c9:0xc9918619cedb7335!8m2!3d44.8217895!4d20.4149187!16s%2Fg%2F11y316h81d?entry=ttu">
                            Bulevar Mihajla Pupina 165b, 11000 Beograd
                          </a>
                        </span>
                      </i>
                    </li>

                    <li className="list-item">
                      <i className="fa fa-phone fa-2x">
                        <span className="contact-text phone">
                          <a href="tel:011-7349823" title="Give me a call">
                            011 734 9823
                          </a>
                        </span>
                      </i>
                    </li>

                    <li className="list-item">
                      <i className="fa fa-instagram fa-2x">
                        <span className="contact-text phone">
                          gymbox.belgrade
                        </span>
                      </i>
                    </li>

                    <li className="list-item">
                      <i className="fa fa-facebook fa-2x">
                        <span className="contact-text place">
                          Gymbox Belgrade
                        </span>
                      </i>
                    </li>
                  </ul>

                  <div>
                    <h3 className="contact-title">{content.HOURS_HEADER}</h3>
                  </div>
                  <div className="price-table">
                    <div className="row price-item">
                      <div className="col-lg-6 col-md-6 price-label">
                        {content.WEEK}
                      </div>
                      <div className="col-lg-6 col-md-6 price">06:30-22:30</div>
                    </div>

                    <div className="row price-item">
                      <div className="col-lg-6 col-md-6 price-label">
                        {content.SATURDAY}
                      </div>
                      <div className="col-lg-6 col-md-6 price">08:00-22:00</div>
                    </div>

                    <div className="row price-item">
                      <div className="col-lg-6 col-md-6 price-label">
                        {content.SUNDAY}
                      </div>
                      <div className="col-lg-6 col-md-6 price">10:00-22:00</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
