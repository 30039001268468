export default function Footer() {
  return (
    <footer className="social-footer">
      <div className="social-footer-left">
        <a href="/">
          <img
            className="logo"
            src={require("../images/logo-gymbox.png")}
            alt=""
          />
        </a>
      </div>
      <div className="social-footer-icons">
        <ul className="menu simple">
          <li>
            <a href="https://www.facebook.com/profile.php?id=61556803865038">
              <i className="fa fa-facebook" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/gymbox.belgrade/">
              <i className="fa fa-instagram" aria-hidden="true"></i>
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}
