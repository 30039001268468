import { useEffect, useState } from "react";
import useNavbarProvider from "../providers/NavbarProvider/useNavbarProvider";
import Modal from "./Modal";
import { Slider } from "./Slider";

export default function GalleryPage() {
  const [show, setShow] = useState(false);
  const [active, setActive] = useState(0);
  const { setNavbarItem } = useNavbarProvider();

  const images = [
    require("../images/galerija/image00001.jpeg"),
    require("../images/galerija/image00002.jpeg"),
    require("../images/galerija/image00003.jpeg"),
    require("../images/galerija/image00004.jpeg"),
    require("../images/galerija/image00005.jpeg"),
    require("../images/galerija/image00006.jpeg"),
    require("../images/galerija/image00007.jpeg"),
    require("../images/galerija/image00008.jpeg"),
    require("../images/galerija/image00009.jpeg"),
    require("../images/galerija/image00010.jpeg"),
    require("../images/galerija/image00011.jpeg"),
    require("../images/galerija/image00012.jpeg"),
    require("../images/galerija/image00013.jpeg"),
    require("../images/galerija/image00014.jpeg"),
    require("../images/galerija/image00015.jpeg"),
    require("../images/galerija/image00016.jpeg"),
    require("../images/galerija/image00017.jpeg"),
    require("../images/galerija/image00018.jpeg"),
    require("../images/galerija/image00019.jpeg"),
    require("../images/galerija/image00020.jpeg"),
    require("../images/galerija/image00021.jpeg"),
    require("../images/galerija/image00022.jpeg"),
  ];

  const handleClick = (index: number) => {
    setActive(index);
    setShow(true);
  };

  const onClose = () => {
    setShow(false);
  };

  useEffect(() => {
    setNavbarItem("gallery");
  }, [setNavbarItem]);

  return (
    <>
      <Modal show={show} onClose={onClose}>
        <Slider images={images} active={active} setActive={setActive} />
      </Modal>
      <section className="trainer_section">
        <div className="container">
          <div className="row">
            {images.map((url, index) => (
              <>
                <div className="col-lg-4 col-md-6 mx-auto">
                  <div className="box">
                    <div className="img-box" onClick={() => handleClick(index)}>
                      <img src={url} alt="" />
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
