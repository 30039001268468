import ContactPage from "./ContactPage";
import CooperationPage from "./CooperationPage";
import GalleryPage from "./GalleryPage";
import HomePage from "./HomePage";
import PricePage from "./PricePage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./Header";

export default function AppContent() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/price" element={<PricePage />} />
        <Route path="/cooperation" element={<CooperationPage />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
    </Router>
  );
}
