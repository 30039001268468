import { useEffect, useMemo } from "react";
import useLanguageProvider from "../providers/LanguageProvider/useLanguageProvider";
import useNavbarProvider from "../providers/NavbarProvider/useNavbarProvider";

export default function PricePage() {
  const { language } = useLanguageProvider();
  const { setNavbarItem } = useNavbarProvider();

  useEffect(() => {
    setNavbarItem("price");
  }, [setNavbarItem]);

  const priceContent = useMemo(() => {
    if (language === "english") {
      return {
        DAILY: "DAILY CARD",
        HALF_MONTH: "HALF A MONTH",
        MONTH: "1 MONTH",
        THREE_MONTHS: "3 MONTHS",
        SIX_MONTHS: "6 MONTHS",
        YEAR: "12 MONTHS",
        NEW_CARD: "ISSUING A NEW CARD",
      };
    }

    return {
      DAILY: "DNEVNI TERMIN",
      HALF_MONTH: "POLA MESECA",
      MONTH: "1 MESEC",
      THREE_MONTHS: "3 MESECA",
      SIX_MONTHS: "6 MESECI",
      YEAR: "12 MESECI",
      NEW_CARD: "IZDAVANJE NOVE KARTICE",
    };
  }, [language]);

  return (
    <>
      <section className="section">
        <div className="container">
          <div className="row price-wrapper">
            <div className="offset-lg-3 offset-md-3 col-lg-6 col-md-6 price-table">
              <div className="row price-item">
                <div className="col-lg-6 col-md-6 col-sm-6 price-label">
                  {priceContent.DAILY}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 price">700 RSD</div>
              </div>

              <div className="row price-item">
                <div className="col-lg-6 col-md-6 col-sm-6 price-label">
                  {priceContent.HALF_MONTH}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 price">2.500 RSD</div>
              </div>

              <div className="row price-item">
                <div className="col-lg-6 col-md-6 col-sm-6 price-label">
                  {priceContent.MONTH}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 price">4.500 RSD</div>
              </div>

              <div className="row price-item">
                <div className="col-lg-6 col-md-6 col-sm-6 price-label">
                  {priceContent.THREE_MONTHS}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 price">12.000 RSD</div>
              </div>

              <div className="row price-item">
                <div className="col-lg-6 col-md-6 col-sm-6 price-label">
                  {priceContent.SIX_MONTHS}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 price">21.000 RSD</div>
              </div>

              <div className="row price-item">
                <div className="col-lg-6 col-md-6 col-sm-6 price-label">
                  {priceContent.YEAR}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 price">38.000 RSD</div>
              </div>

              <div className="row price-item">
                <div className="col-lg-6 col-md-6 col-sm-6 price-label">
                  {priceContent.NEW_CARD}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 price">500 RSD</div>
              </div>
            </div>
          </div>
        </div>
        <div className="price-logo">
          <img src={require("../images/logo-gymbox.png")} alt="" />
        </div>
      </section>
    </>
  );
}
