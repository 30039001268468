import ReactOwlCarousel from "react-owl-carousel";
import useLanguageProvider from "../providers/LanguageProvider/useLanguageProvider";

import useNavbarProvider from "../providers/NavbarProvider/useNavbarProvider";
import { useEffect } from "react";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default function HomePage() {
  const { language } = useLanguageProvider();
  const { setNavbarItem } = useNavbarProvider();

  useEffect(() => {
    setNavbarItem("home");
  }, [setNavbarItem]);

  const homePageTitle = () => {
    if (language === "serbian") {
      return "TRENIRAJ KAO ŠAMPION";
    }
    return "TRAIN LIKE A CHAMPION";
  };

  const homePageDescription = () => {
    if (language === "english") {
      return "Founded in the centre of New Belgrade, GymBox is the elite fitness and boxing-gym concept. We are the trailblazers in fitness and wellbeing, pushing boundaries in what a boxing & gym should and could be. Progressive training modalities, elite-standard equipment and facilities and expert-level trainers combined in an spacious luxury space.";
    }
    return "Osnovan u centru Novog Beograda, GymBox predstavlja elitni fitness i boxing koncept teretane. Mi kao tim predstavljamo inovatore u oblasti fitnessa i zdravog života, pomeramo granice u vezi toga šta boxing i teretana mogu i treba da budu. Progresivni modaliteta treninga, elitna i kvalitetna oprema i sadržaji, kao i treneri sa ekspertskim nivoom znanja, ukombinovani su u prostranom i luksuznom prostoru.";
  };

  return (
    <>
      <ReactOwlCarousel
        className="owl-theme"
        nav={true}
        dotsEach={true}
        autoplay={true}
        autoplayTimeout={5000}
        items={1}
        dots={true}
      >
        <div className="item detail-box">
          <img src={require("../images/pocetna/teretana-spolja.jpeg")} alt="" />
        </div>
        <div className="item detail-box">
          <img src={require("../images/pocetna/teretana-sprave.jpeg")} alt="" />
        </div>
        <div className="item detail-box">
          <img src={require("../images/pocetna/teretana-kardio.jpeg")} alt="" />
        </div>
        <div className="item detail-box">
          <img
            src={require("../images/pocetna/teretana-svlacionica.jpeg")}
            alt=""
          />
        </div>
        <div className="item detail-box">
          <img src={require("../images/pocetna/teretana-pult.jpeg")} alt="" />
        </div>
      </ReactOwlCarousel>
      ;
      <div className="about-us-area">
        <div className="offset-lg-2 col-lg-8 col-md-12">
          <h2>{homePageTitle()}</h2>
          <p>{homePageDescription()}</p>
        </div>
      </div>
    </>
  );
}
