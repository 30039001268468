import "./App.css";
import LanguageProvider from "./providers/LanguageProvider/LanguageProvider";
import NavbarProvider from "./providers/NavbarProvider/NavbarProvider";
import AppContent from "./components/AppContent";
import Footer from "./components/Footer";

function App() {
  return (
    <LanguageProvider>
      <NavbarProvider>
        <AppContent />
        <Footer />
      </NavbarProvider>
    </LanguageProvider>
  );
}

export default App;
