import React, { useState } from "react";
import { Provider } from "./context";

export default function LanguageProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [language, setLanguage] = useState("serbian");

  return <Provider value={{ language, setLanguage }}>{children}</Provider>;
}
