import React, { useMemo, useState } from "react";

import useLanguageProvider from "../providers/LanguageProvider/useLanguageProvider";
import useNavbarProvider from "../providers/NavbarProvider/useNavbarProvider";
import { NavLink } from "react-router-dom";

export default function Header() {
  const { navbarItem } = useNavbarProvider();
  const { language, setLanguage } = useLanguageProvider();

  const [showNavbar, setShowNavbar] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const hideNavbar = () => {
    setShowNavbar(false);
  };

  const flagContent = useMemo(() => {
    switch (language) {
      case "serbian":
        return (
          <button onClick={() => setLanguage("english")} className="nav-link">
            <img
              className="flag-logo"
              src={require("../images/uk.jpg")}
              alt=""
            />
          </button>
        );
      case "english":
        return (
          <button onClick={() => setLanguage("serbian")} className="nav-link">
            <img
              className="flag-logo"
              src={require("../images/serbia.png")}
              alt=""
            />
          </button>
        );
    }
  }, [language, setLanguage]);

  const navbarContent = useMemo(() => {
    switch (language) {
      case "serbian":
        return {
          HOME: "Početna",
          GALLERY: "Galerija",
          PRICE: "Cenovnik",
          COOPERATION: "Saradnja",
          TRAINERS: "Naši treneri",
          CONTACT: "Kontakt",
        };
      case "english":
        return {
          HOME: "Home",
          GALLERY: "Gallery",
          PRICE: "Prices",
          COOPERATION: "Cooperation",
          TRAINERS: "Our trainers",
          CONTACT: "Contact",
        };
      default:
        return {};
    }
  }, [language]);

  const ulContent = useMemo(() => {
    switch (navbarItem) {
      case "home":
        return (
          <>
            <li className="nav-item active">
              <NavLink className="nav-link" to="/">
                {navbarContent.HOME}
                <span className="sr-only">(current)</span>
              </NavLink>
            </li>
            <li className="nav-item ">
              <NavLink className="nav-link" to="/gallery" onClick={hideNavbar}>
                {navbarContent.GALLERY}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/price" onClick={hideNavbar}>
                {navbarContent.PRICE}
              </NavLink>
            </li>
            <li className="nav-item ">
              <NavLink
                className="nav-link"
                to="/cooperation"
                onClick={hideNavbar}
              >
                {navbarContent.COOPERATION}
              </NavLink>
            </li>
            {/* <li className="nav-item ">
              <button className="nav-link" onClick={setTrainersPage}>
                {navbarContent.TRAINERS}
              </button>
            </li> */}
            <li className="nav-item">
              <NavLink className="nav-link" to="/contact" onClick={hideNavbar}>
                {navbarContent.CONTACT}
              </NavLink>
            </li>
          </>
        );
      case "gallery":
        return (
          <>
            <li className="nav-item">
              <NavLink className="nav-link" to="/" onClick={hideNavbar}>
                {navbarContent.HOME}
                <span className="sr-only">(current)</span>
              </NavLink>
            </li>
            <li className="nav-item active">
              <NavLink className="nav-link" to="/gallery" onClick={hideNavbar}>
                {navbarContent.GALLERY}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/price" onClick={hideNavbar}>
                {navbarContent.PRICE}
              </NavLink>
            </li>
            <li className="nav-item ">
              <NavLink
                className="nav-link"
                to="/cooperation"
                onClick={hideNavbar}
              >
                {navbarContent.COOPERATION}
              </NavLink>
            </li>
            {/* <li className="nav-item ">
              <button className="nav-link" onClick={setTrainersPage}>
                {navbarContent.TRAINERS}
              </button>
            </li> */}
            <li className="nav-item">
              <NavLink className="nav-link" to="/contact" onClick={hideNavbar}>
                {navbarContent.CONTACT}
              </NavLink>
            </li>
          </>
        );
      case "price":
        return (
          <>
            <li className="nav-item">
              <NavLink className="nav-link" to="/" onClick={hideNavbar}>
                {navbarContent.HOME}
                <span className="sr-only">(current)</span>
              </NavLink>
            </li>
            <li className="nav-item ">
              <NavLink className="nav-link" to="/gallery" onClick={hideNavbar}>
                {navbarContent.GALLERY}
              </NavLink>
            </li>
            <li className="nav-item active">
              <NavLink className="nav-link" to="/price" onClick={hideNavbar}>
                {navbarContent.PRICE}
              </NavLink>
            </li>
            <li className="nav-item ">
              <NavLink
                className="nav-link"
                to="/cooperation"
                onClick={hideNavbar}
              >
                {navbarContent.COOPERATION}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/contact" onClick={hideNavbar}>
                {navbarContent.CONTACT}
              </NavLink>
            </li>
          </>
        );
      case "cooperation":
        return (
          <>
            <li className="nav-item">
              <NavLink className="nav-link" to="/" onClick={hideNavbar}>
                {navbarContent.HOME}
                <span className="sr-only">(current)</span>
              </NavLink>
            </li>
            <li className="nav-item ">
              <NavLink className="nav-link" to="/gallery" onClick={hideNavbar}>
                {navbarContent.GALLERY}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/price" onClick={hideNavbar}>
                {navbarContent.PRICE}
              </NavLink>
            </li>
            <li className="nav-item active">
              <NavLink
                className="nav-link"
                to="/cooperation"
                onClick={hideNavbar}
              >
                {navbarContent.COOPERATION}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/contact" onClick={hideNavbar}>
                {navbarContent.CONTACT}
              </NavLink>
            </li>
          </>
        );
      case "contact":
        return (
          <>
            <li className="nav-item">
              <NavLink className="nav-link" to="/" onClick={hideNavbar}>
                {navbarContent.HOME}
                <span className="sr-only">(current)</span>
              </NavLink>
            </li>
            <li className="nav-item ">
              <NavLink className="nav-link" to="/gallery" onClick={hideNavbar}>
                {navbarContent.GALLERY}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/price" onClick={hideNavbar}>
                {navbarContent.PRICE}
              </NavLink>
            </li>
            <li className="nav-item ">
              <NavLink
                className="nav-link"
                to="/cooperation"
                onClick={hideNavbar}
              >
                {navbarContent.COOPERATION}
              </NavLink>
            </li>
            <li className="nav-item active">
              <NavLink className="nav-link" to="/contact" onClick={hideNavbar}>
                {navbarContent.CONTACT}
              </NavLink>
            </li>
          </>
        );
      default:
        return;
    }
  }, [navbarItem, navbarContent]);

  return (
    <div className="header-div">
      <header className="header_section">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg custom_nav-container ">
            <a className="navbar-brand" href="/">
              <img
                src={require("../images/logo-gymbox.png")}
                alt=""
                className="logo-header"
              />
            </a>

            <div className="menu-icon" onClick={handleShowNavbar}>
              <i className="fa fa-2x fa-bars" />
            </div>

            <div
              className={`d-flex ml-auto flex-column flex-lg-row align-items-center nav-elements ${showNavbar && "active"}`}
            >
              <ul className="navbar-nav">
                {ulContent}
                <li className="nav-item">{flagContent}</li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
}
