import React, { Dispatch, SetStateAction } from "react";

const defaultValue = {
  navbarItem: "home",
  setNavbarItem: ((value: string) => undefined) as Dispatch<
    SetStateAction<string>
  >,
};

const NavbarContext = React.createContext(defaultValue);

const { Provider, Consumer } = NavbarContext;

export { NavbarContext, Provider, Consumer };
